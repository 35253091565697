var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-app":""}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 offset-md-0"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('ul',{staticClass:"list-group"},[_c('li',{staticClass:"list-group-item"},[_c('strong',[_vm._v("Nombre:")]),_vm._v(" "+_vm._s(_vm.producto.name)+" "),_vm._m(1)]),_c('li',{staticClass:"list-group-item list-group-item-dark"},[_c('strong',[_vm._v("Sku:")]),_vm._v(" "+_vm._s(_vm.producto.sku)+" "),_vm._m(2)]),_c('li',{staticClass:"list-group-item"},[_c('strong',[_vm._v("Stock:")]),_vm._v(" "+_vm._s(_vm.producto.sum_quantity)+" "),_vm._m(3)]),_c('li',{staticClass:"list-group-item"},[_c('strong',[_vm._v("Stock Físico:")]),_vm._v(" "+_vm._s(_vm.producto.sum_physical_quantity)+" "),_vm._m(4)]),_c('li',{staticClass:"list-group-item"},[_c('strong',[_vm._v("Stock Reservado: ")]),(_vm.producto.sum_reserved_quantity == 0)?_c('span',[_vm._v(" "+_vm._s(_vm.producto.sum_reserved_quantity)+" ")]):_vm._e(),(_vm.producto.sum_reserved_quantity > 0)?_c('span',{staticClass:"text-left"},[_c('strong',[_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":'#'},on:{"click":function($event){return _vm.listadoOrdenesReserva(_vm.producto)}}},[_vm._v(_vm._s(_vm.producto.sum_reserved_quantity))])])]):_vm._e(),_vm._m(5)])])])])]),_c('div',{staticClass:"col-md-6"})]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header bg-secondary"},[_vm._v(" Bodega "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"badge badge-warning badge-pill float-right sdw"},on),[_c('i',{staticClass:"fas fa-boxes text-dark"})])]}}])},[_c('span',[_vm._v("Producto en Bodegas")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"badge badge-secondary badge-pill float-right sdw pointer mr-3",on:{"click":function($event){return _vm.reinitializeStockBySku()}}},on),[_c('i',{staticClass:"fas fa-sync text-success"})])]}}])},[_c('span',[_vm._v("Actualizar Tabla")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"badge badge-success badge-pill pointer float-right sdw mr-3",on:{"click":_vm.exportWarehouseExcel}},on),[_c('i',{staticClass:"fas fa-file-excel text-white"})])]}}])},[_c('span',[_vm._v("Exportar Bodegas a Excel")])])],1),_c('div',{staticClass:"card-body"},[_c('v-data-table',{staticClass:"elevation-1 datatable",attrs:{"headers":_vm.headersBodega,"items":_vm.getStockBySku,"search":_vm.search,"sort-by":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('div',{staticClass:"kt-input-icon kt-input-icon--left"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control form-control-md col-md-8",attrs:{"append-icon":"search","label":"Búsqueda","type":"text","placeholder":"Buscar..."},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}),_c('span',{staticClass:"kt-input-icon__icon"},[_c('span',[_c('i',{staticClass:"la la-search"})])])]),_c('v-spacer')],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('router-link',{attrs:{"to":("/inventario/stock/productos/" + (encodeURIComponent(item.product.sku)) + "/" + (item.warehouse.id))}},[_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"#93a2dd","small":""}},on),[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Producto en Bodega")])])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"#87CEFA","loading":_vm.loadingStockSku},on:{"click":function($event){return _vm.reinitializeStockBySku()}}},[_vm._v("Cargando ..")])]},proxy:true}])})],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header bg-secondary"},[_vm._v(" Movimientos "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"badge badge-success badge-pill float-right sdw"},on),[_c('i',{staticClass:"fas fa-exchange-alt text-white"})])]}}])},[_c('span',[_vm._v("Movimientos en Bodega")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"badge badge-secondary badge-pill float-right sdw pointer mr-3",on:{"click":function($event){return _vm.reinitializeMovementsBySku()}}},on),[_c('i',{staticClass:"fas fa-sync text-success"})])]}}])},[_c('span',[_vm._v("Actualizar Tabla")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"badge badge-success badge-pill pointer float-right sdw mr-3",on:{"click":_vm.exportMovementsExcel}},on),[_c('i',{staticClass:"fas fa-file-excel text-white"})])]}}])},[_c('span',[_vm._v("Exportar Movimientos a Excel")])])],1),_c('div',{staticClass:"card-body"},[_c('v-data-table',{staticClass:"elevation-1 datatable",attrs:{"headers":_vm.headersMovimientos,"items":_vm.getMovementsBySku,"search":_vm.search,"sort-by":"updated_at","sort-desc":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('div',{staticClass:"kt-input-icon kt-input-icon--left"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control form-control-md col-md-8",attrs:{"append-icon":"search","label":"Búsqueda","type":"text","placeholder":"Buscar..."},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}),_c('span',{staticClass:"kt-input-icon__icon"},[_c('span',[_c('i',{staticClass:"la la-search"})])])]),_c('v-spacer')],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"#87CEFA","loading":_vm.loadingMovementSku},on:{"click":function($event){return _vm.reinitializeMovementsBySku()}}},[_vm._v("Cargando ..")])]},proxy:true},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [(parseInt(item.sign) > 0 && parseInt(item.quantity) != 0)?_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(Math.abs(parseInt(item.quantity)))+" "),_c('v-icon',{attrs:{"color":"#00ff00"}},[_vm._v("mdi-arrow-up-bold")])],1):_vm._e(),(parseInt(item.sign) < 0)?_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(Math.abs(parseInt(item.quantity)))+" "),_c('v-icon',{attrs:{"color":"#ff0000"}},[_vm._v("mdi-arrow-down-bold")])],1):_vm._e(),(parseInt(item.sign) > 0 && parseInt(item.quantity) == 0)?_c('td',{staticClass:"text-right"},[_c('v-icon',{attrs:{"color":"#0000ff"}},[_vm._v("mdi-arrow-down-equal")])],1):_vm._e()]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$moment(item.updated_at).format('LLLL'))+" ")])]}},{key:"item.order_id",fn:function(ref){
var item = ref.item;
return [(item.order_id)?_c('td',{staticClass:"text-left"},[_c('a',{staticClass:"text-decoration-none",attrs:{"href":'/#/ordenes/'+item.order_id,"target":"_blank"}},[_vm._v(_vm._s(item.order_id))])]):_vm._e()]}}])})],1)])])])]),_c('dialog-listado-ordenes-reserva')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header bg-primary text-white"},[_c('h3',[_vm._v("Producto")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"badge badge-dark badge-pill float-right"},[_c('i',{staticClass:"fas fa-box"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"badge badge-secondary badge-pill float-right"},[_c('i',{staticClass:"fas fa-cubes"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"badge badge-dark badge-pill float-right"},[_c('i',{staticClass:"fas fa-box"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"badge badge-dark badge-pill float-right"},[_c('i',{staticClass:"fas fa-box"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"badge badge-dark badge-pill float-right"},[_c('i',{staticClass:"fas fa-box"})])}]

export { render, staticRenderFns }