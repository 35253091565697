<template>
  <div data-app>
    <div class="container-fluid">
      <!-- 1°first ROW -->
      <div class="row">
        <div class="col-md-12 offset-md-0">
          <!-- 1°first Card -->
          <div class="card">
            <div class="card-header bg-primary text-white">
              <h3>Producto</h3>
            </div>
            <div class="card-body">
              <!-- 1°first List-->
              <ul class="list-group">
                <li class="list-group-item">
                  <strong>Nombre:</strong>
                  {{producto.name}}
                  <span
                    class="badge badge-dark badge-pill float-right"
                  >
                    <i class="fas fa-box"></i>
                  </span>
                </li>
                <li class="list-group-item list-group-item-dark">
                  <strong>Sku:</strong>
                  {{producto.sku}}
                  <span
                    class="badge badge-secondary badge-pill float-right"
                  >
                    <i class="fas fa-cubes"></i>
                  </span>
                </li>
                <li class="list-group-item">
                  <strong>Stock:</strong>
                  {{producto.sum_quantity}}
                  <span
                    class="badge badge-dark badge-pill float-right"
                  >
                    <i class="fas fa-box"></i>
                  </span>
                </li>
                <li class="list-group-item">
                  <strong>Stock Físico:</strong>
                  {{producto.sum_physical_quantity}}
                  <span
                    class="badge badge-dark badge-pill float-right"
                  >
                    <i class="fas fa-box"></i>
                  </span>
                </li>
                <li class="list-group-item">
                  <strong>Stock Reservado: </strong>
                  <span v-if="producto.sum_reserved_quantity == 0"> {{producto.sum_reserved_quantity}} </span>
                  
                  <span class="text-left" v-if="producto.sum_reserved_quantity > 0"><strong>
                    <a :href="'#'" @click="listadoOrdenesReserva(producto)" style="text-decoration:underline;">{{ producto.sum_reserved_quantity }}</a>
                    </strong>
                  </span>
                  <span class="badge badge-dark badge-pill float-right" > <i class="fas fa-box"></i> </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6"></div>
      </div>
      <!-- 2°second ROW -->
      <div class="row">
        <div class="col-md-12">
          <!-- 2°second Card -->
          <div class="card">
            <div class="card-header bg-secondary">
              Bodega
              <v-tooltip top>
                <template v-slot:activator="{ on}">
                  <span v-on="on" class="badge badge-warning badge-pill float-right sdw">
                    <i class="fas fa-boxes text-dark"></i>
                  </span>
                </template>
                <span>Producto en Bodegas</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on}">
                  <span
                    v-on="on"
                    class="badge badge-secondary badge-pill float-right sdw pointer mr-3"
                    @click="reinitializeStockBySku()"
                  >
                    <i class="fas fa-sync text-success"></i>
                  </span>
                </template>
                <span>Actualizar Tabla</span>
              </v-tooltip>
              <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span
                  v-on="on"
                  @click="exportWarehouseExcel"
                  class="badge badge-success badge-pill pointer float-right sdw mr-3"
                >
                  <i class="fas fa-file-excel text-white"></i>
                </span>
              </template>
              <span>Exportar Bodegas a Excel</span>
            </v-tooltip>
            </div>
            <div class="card-body">
              <v-data-table
                :headers="headersBodega"
                :items="getStockBySku"
                :search="search"
                sort-by="id"
                class="elevation-1 datatable"
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <div class="kt-input-icon kt-input-icon--left">
                      <input
                        v-model="search"
                        append-icon="search"
                        label="Búsqueda"
                        type="text"
                        class="form-control form-control-md col-md-8"
                        placeholder="Buscar..."
                      />
                      <span class="kt-input-icon__icon">
                        <span>
                          <i class="la la-search"></i>
                        </span>
                      </span>
                    </div>
                    <!--<v-text-field
                      class="text-xs-center"
                      v-model="search"
                      append-icon="search"
                      label="Búsqueda"
                    ></v-text-field>
                    <v-divider class="mx-4" inset vertical></v-divider>-->
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <router-link
                        :to="`/inventario/stock/productos/${encodeURIComponent(item.product.sku)}/${item.warehouse.id}`"
                      >
                        <v-icon v-on="on" color="#93a2dd" small class="mr-2">mdi-eye</v-icon>
                      </router-link>
                    </template>
                    <span>Ver Producto en Bodega</span>
                  </v-tooltip>
                </template>
                <template v-slot:no-data>
                  <v-btn
                    color="#87CEFA"
                    :loading="loadingStockSku"
                    @click="reinitializeStockBySku()"
                  >Cargando ..</v-btn>
                </template>
              </v-data-table>
            </div>
          </div>
        </div>
      </div>
      <!-- 3°third ROW -->
      <div class="row">
        <div class="col-md-12">
          <!-- 3°third Card -->
          <div class="card">
            <div class="card-header bg-secondary">
              Movimientos
              <v-tooltip top>
                <template v-slot:activator="{ on}">
                  <span v-on="on" class="badge badge-success badge-pill float-right sdw">
                    <i class="fas fa-exchange-alt text-white"></i>
                  </span>
                </template>
                <span>Movimientos en Bodega</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on}">
                  <span
                    v-on="on"
                    class="badge badge-secondary badge-pill float-right sdw pointer mr-3"
                    @click="reinitializeMovementsBySku()"
                  >
                    <i class="fas fa-sync text-success"></i>
                  </span>
                </template>
                <span>Actualizar Tabla</span>
              </v-tooltip>
              <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span
                  v-on="on"
                  @click="exportMovementsExcel"
                  class="badge badge-success badge-pill pointer float-right sdw mr-3"
                >
                  <i class="fas fa-file-excel text-white"></i>
                </span>
              </template>
              <span>Exportar Movimientos a Excel</span>
            </v-tooltip>
            </div>
            <div class="card-body">
              <v-data-table
                :headers="headersMovimientos"
                :items="getMovementsBySku"
                :search="search"
                sort-by="updated_at"
                :sort-desc="true"
                class="elevation-1 datatable"
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <div class="kt-input-icon kt-input-icon--left">
                      <input
                        v-model="search"
                        append-icon="search"
                        label="Búsqueda"
                        type="text"
                        class="form-control form-control-md col-md-8"
                        placeholder="Buscar..."
                      />
                      <span class="kt-input-icon__icon">
                        <span>
                          <i class="la la-search"></i>
                        </span>
                      </span>
                    </div>
                    <!--<v-text-field
                      class="text-xs-center"
                      v-model="search"
                      append-icon="search"
                      label="Búsqueda"
                    ></v-text-field>
                    <v-divider class="mx-4" inset vertical></v-divider>-->
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
                <template v-slot:no-data>
                  <v-btn
                    color="#87CEFA"
                    :loading="loadingMovementSku"
                    @click="reinitializeMovementsBySku()"
                  >Cargando ..</v-btn>
                </template>
                <template v-slot:item.quantity="{ item }">
                  <td class="text-right" v-if="parseInt(item.sign) > 0 && parseInt(item.quantity) != 0">
                    {{Math.abs(parseInt(item.quantity))}} <v-icon color="#00ff00">mdi-arrow-up-bold</v-icon>
                  </td>
                  <td class="text-right" v-if="parseInt(item.sign) < 0">
                    {{Math.abs(parseInt(item.quantity))}} <v-icon color="#ff0000">mdi-arrow-down-bold</v-icon>
                  </td>
                  <td class="text-right" v-if="parseInt(item.sign) > 0 && parseInt(item.quantity) == 0">
                    <v-icon color="#0000ff">mdi-arrow-down-equal</v-icon>
                  </td>
                </template>
                <template v-slot:item.updated_at="{ item }">
                  <td class="text-left">
                    {{$moment(item.updated_at).format('LLLL')}}
                  </td>
                </template>
                <template v-slot:item.order_id="{ item }">
                  <td class="text-left" v-if="item.order_id">
                    <a class="text-decoration-none" :href="'/#/ordenes/'+item.order_id" target="_blank" >{{item.order_id}}</a>
                  </td>
                </template>
              </v-data-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <dialog-listado-ordenes-reserva ></dialog-listado-ordenes-reserva>
  </div>
</template>


<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import DialogListadoOrdenesReserva from "@/views/oms/Inventario/DialogListadoOrdenesReserva";
import moment from "moment";

export default {
  components: {
    DialogListadoOrdenesReserva,
  },
  props: ["sku"],
  data: () => ({
    loadingStockSku: false,
    loadingMovementSku: false,
    producto: {
      id: "",
      name: "",
      sku: "",
      store_id: "",
      sum_quantity: "",
      sum_physical_quantity: "",
      sum_reserved_quantity: "",
    },
    readonly: true,
    search: "",
    movimientos: [],
    getStockBySku: [],
    getMovementsBySku: [],
    windowSize: {
      x: 0,
      y: 0,
    },
    headersBodega: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Stock", value: "quantity" },
      { text: "Nombre", value: "warehouse.name" },
      { text: "Ubicación", value: "location.code" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    headersMovimientos: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Fecha", value: "updated_at" },
      { text: "Stock", value: "quantity" },
      { text: "Balance", value: "stock_balance" },
      { text: "Razón", value: "reason.name" },
      { text: "Bodega", value: "warehouse.name" },
      { text: "# Orden", value: "order_id" },
    ],
  }),
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "➀ Lista de Productos", route: "/inventario/stock/productos" },
      {
        title: "➁ Detalle Producto",
        route: `/inventario/stock/productos/${encodeURIComponent(this.sku)}`,
      },
    ]);

    this.onResize();
  },
  created() {
    this.loadingStockSku = true;
    this.loadingMovementSku = true;
    this.fetchProduct();
    this.fetchProductMovements();
  },

  methods: {
    initialize() {},
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    fetchProduct() {
      var vm = this;
      vm.sum_quantity = 0;
      vm.sum_physical_quantity = 0;
      vm.sum_reserved_quantity = 0;
      vm.producto.sum_reserved_quantity = 0;
      vm.producto.sum_physical_quantity = 0;
      vm.producto.sum_quantity = 0;

      this.axios({
        url: "inventory/stock/products/encoded/" + btoa(vm.sku),
        method: "GET",
      })
        .then((response) => {
          this.loadingStockSku = false;
          // console.log(response.data.data[0].warehouse.id);
          vm.getStockBySku = response.data.data;
          vm.producto.id = response.data.data[0].product.id;
          vm.producto.name = response.data.data[0].product.name;
          vm.producto.sku = response.data.data[0].product.sku;
          vm.producto.store_id = response.data.data[0].product.store_id;

          response.data.data.map(function (value, key) {
            vm.producto.sum_quantity =
              Number(vm.producto.sum_quantity) + Number(value.quantity);

            vm.producto.sum_physical_quantity =
              Number(vm.producto.sum_physical_quantity) +
              Number(value.physical_quantity);
              
            vm.producto.sum_reserved_quantity =
              Number(vm.producto.sum_reserved_quantity) +
              Number(value.reserved_quantity);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchProductMovements() {
      var vm = this;
      this.loadingMovementSku = false;
      this.axios({
        url: "inventory/stock/products/movements/encoded/" + btoa(vm.sku),
        method: "GET",
      })
        .then((response) => {
          console.log(response.data);
          console.log(response.data.data);

          vm.getMovementsBySku = response.data.data;
          // vm.producto.name = response.data.data[0].product.name;
          // vm.producto.sku = response.data.data[0].product.sku;

          // response.data.data.map(function(value, key) {
          //   vm.producto.sum_quantity = Number(vm.producto.sum_quantity) + Number(value.quantity);
          //   vm.producto.sum_physical_quantity = Number(vm.producto.sum_physical_quantity) + Number(value.physical_quantity);
          //   vm.producto.sum_reserved_quantity = Number(vm.producto.sum_reserved_quantity) + Number(value.reserved_quantity);
          // });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    reinitializeStockBySku() {
      this.getStockBySku = [];
      this.loadingStockSku = true;
      this.fetchProduct();
    },
    reinitializeMovementsBySku() {
      this.getMovementsBySku = [];
      this.loadingMovementSku = true;
      this.fetchProductMovements();
    },
    exportWarehouseExcel() {
      var vm = this;
      let headers = vm.headersBodega.map((e) => e.text);
      let datos = vm.getStockBySku;
      this.axios({
        url: "excel/from_array",
        method: "POST",
        data: {
          data: datos,
          cabeceras: headers,
        },
        responseType: "blob",
      })
        .then((response) => {
          const newBlob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          const data = window.URL.createObjectURL(newBlob);
          window.open(data, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportMovementsExcel() {
      var vm = this;
      let headers = vm.headersMovimientos.map((e) => e.text);
      let datos = vm.getMovementsBySku;
      this.axios({
        url: "excel/from_array",
        method: "POST",
        data: {
          data: datos,
          cabeceras: headers,
        },
        responseType: "blob",
      })
        .then((response) => {
          const newBlob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          const data = window.URL.createObjectURL(newBlob);
          window.open(data, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    destroyed() {},
    listadoOrdenesReserva(datos) {
      this.$emit("openDialogListadoOrdenesReserva", datos);
    },
  },
};
</script>